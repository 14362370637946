import { BaseModel } from "./base.model";

export class StaticPage extends BaseModel {
  constructor(
    public active: boolean = false,
    public link: string = "",
    public html: { [lang: string]: string } = {}
  ) {
    super();
  }
}
