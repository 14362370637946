import SettingsService from "@/services/SettingsService";

interface GlobalAccessCodeState {
  code: { value: string };
}

const GlobalAccessCodeStore = {
  namespaced: true,
  state: {
    code: { value: "" },
  },
  getters: {
    code: (state: GlobalAccessCodeState) => state.code,
  },
  mutations: {
    SET_CODE(state: GlobalAccessCodeState, code: { value: string }) {
      state.code.value = code.value;
    },
  },
  actions: {
    async fetch({ commit }: any) {
      const globalAccessCodeSetting = await SettingsService.fetchOne(
        "access-code"
      );
      commit("SET_CODE", globalAccessCodeSetting.setting_value);
    },
    async update({ commit }: any, code: string) {
      const result = await SettingsService.update("access-code", code);
    },
  },
} as any;

export default GlobalAccessCodeStore;
