import axios from "@/plugins/axios.plugin";

import NotificationsService from "@/services/NotificationsService";
import { PaginatedResult } from "@/shared/types/paginated-result.class";

interface NotificationsState {
  notifications: PaginatedResult<any>;
}

const Notifications = {
  namespaced: true,
  state: {
    notifications: {} as PaginatedResult<any>,
  },
  getters: {
    all: (state: NotificationsState) => state.notifications,
  },
  mutations: {
    SET_ALL(state: NotificationsState, notifications: PaginatedResult<any>) {
      state.notifications = notifications;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const notifications = await NotificationsService.fetchAll();
      commit("SET_ALL", notifications);
    },
    async create({ commit }: any, payload: any) {
      const result = await NotificationsService.create(payload);
    },
  },
} as any;

export default Notifications;
