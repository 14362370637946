import SettingsService from "@/services/SettingsService";

const Tags = {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    all: (state: { all: any }) => state.all,
  },
  mutations: {
    SET_ALL(state: { all: any }, all: any) {
      state.all = all
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const all = await SettingsService.fetchOnePublic("tags");
      commit("SET_ALL", all.setting_value);
    },
    async update({ commit }: any, data: any) {
      await SettingsService.update("tags", data);
    }
  }
} as any

export default Tags