import axios from "@/plugins/axios.plugin";
import SettingsService from "@/services/SettingsService";

interface ConfigState {
  all: { [key: string]: any };
}

const Config = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: ConfigState ) => state.all,
  },
  mutations: {
    SET_ALL(state: ConfigState, config: any) {
      state.all = config;
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const config = await SettingsService.fetchOnePublic("config");
      commit("SET_ALL", config.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("config", payload);
    },
  },
} as any;

export default Config;