import { BaseModel } from "./base.model";

export class Room extends BaseModel {
  constructor(
    public name: string,
    public description: string,
    public room_url?: string,
    public image_url?: string
  ) {
    super();
  }
}
