import { CustomForm } from "@/models/custom-form.model";
import BaseService from "./base-service";

class CustomFormsService extends BaseService<CustomForm> {
  get endpoint() {
    return "/custom-forms";
  }
}

export default new CustomFormsService();
