import AuthService from "@/services/AuthService";

const storedUser = localStorage.getItem("user");
const storedToken = localStorage.getItem("token");

interface AuthState {
  status: { loggedIn: boolean };
  user: any;
  token: string;
}

const User = {
  namespaced: true,
  state: {
    status: storedUser ? { loggedIn: true } : { loggedIn: false },
    user: storedUser ? JSON.parse(storedUser) : null,
    token: storedToken ? JSON.parse(storedToken) : null,
  },
  getters: {
    token(state: AuthState) {
      return state.token;
    },
    isAdmin(state: AuthState) {
      return state.user?.role === "admin";
    },
    isModerator(state: AuthState) {
      return state.user?.role === "moderator";
    },
    isLoggedIn(state: AuthState) {
      return state.status.loggedIn;
    },
    currentUser(state: AuthState) {
      return state.user;
    },
    registrationCompleted(state: AuthState) {
      return Boolean(state.user?.registration_completed);
    },
    preferredLivestream(state: AuthState) {
      return state.user?.preferred_livestream;
    },
  },
  mutations: {
    restoreUser(state: AuthState) {
      let user = localStorage.getItem("user");
      let token = localStorage.getItem("token") as string;
      if (user) {
        user = JSON.parse(user);
      }
      if (token) {
        token = JSON.parse(token);
      }
      state.user = user;
      state.token = token;
    },
    loginSuccess(state: AuthState, loginResult: { token: string; user: any }) {
      state.status.loggedIn = true;
      state.user = loginResult.user;
      state.token = loginResult.token;
      localStorage.setItem("user", JSON.stringify(loginResult.user));
      localStorage.setItem("token", JSON.stringify(loginResult.token));
    },
    loginFailure(state: AuthState) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state: AuthState) {
      state.status.loggedIn = false;
      state.user = null;
      state.token = "";
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    registerSuccess(
      state: AuthState,
      loginResult: { token: string; user: any }
    ) {
      state.status.loggedIn = true;
      state.user = loginResult.user;
      state.token = loginResult.token;
      localStorage.setItem("user", JSON.stringify(loginResult.user));
      localStorage.setItem("token", JSON.stringify(loginResult.token));
    },
    registerFailure(state: AuthState) {
      state.status.loggedIn = false;
    },
  },
  actions: {
    async me({ commit }): Promise<any> {
      try {
        const me = await AuthService.me();
        localStorage.setItem("user", JSON.stringify(me.user));
        commit("restoreUser");
        return me;
      } catch (e) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        commit("loginFailure");
        commit("restoreUser");
        return false;
      }
    },
    async addRegistration(context: any, data: any): Promise<any> {
      return AuthService.addRegistration(data);
    },
    async addFormRegistration(context: any, data: any): Promise<any> {
      return AuthService.addFormRegistration(data);
    },
    async adminLogin({ commit }, data: any): Promise<any> {
      try {
        const loginResult = await AuthService.adminLogin(
          data.email,
          data.password
        );
        commit("loginSuccess", loginResult);
        return loginResult.user;
      } catch (error) {
        commit("loginFailure");
        throw error;
      }
    },
    async login({ commit }, data: any): Promise<any> {
      try {
        const loginResult = await AuthService.login(data.email, data.code);
        commit("loginSuccess", loginResult);
        return loginResult.user;
      } catch (error) {
        commit("loginFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        throw error;
      }
    },
    signOut({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register(
      { commit }: any,
      data: {
        email: string;
        first_name: string;
        last_name: string;
        role: string;
        code?: string;
      }
    ): Promise<any> {
      return AuthService.register(
        data.email,
        data.first_name,
        data.last_name,
        data.role,
        data.code
      ).then(
        (user) => {
          commit("registerSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("registerFailure");
          console.log("ERROR", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
    },
  },
} as any;

export default User;
