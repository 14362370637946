import SettingsService from "@/services/SettingsService";

const Whitelist = {
  namespaced: true,
  state: {
    domains: [],
  },
  getters: {
    all: (state: { pages: any }) => state.pages,
  },
  mutations: {
    SET_ALL(state: { domains: any }, domains: any) {
      state.domains = domains
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
        const domains = await SettingsService.fetchOnePublic("whitelist");
        commit("SET_ALL", domains.setting_value);
      },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("whitelist", data);
    }
  }
} as any

export default Whitelist