import { CustomForm } from "@/models/custom-form.model";
import CustomFormsService from "@/services/CustomFormsService";

interface CustomFormsState {
  all: CustomForm[];
  current: CustomForm;
}

const CustomForms = {
  namespaced: true,
  state: {
    all: [],
    current: new CustomForm(),
  },
  getters: {
    all: (state: CustomFormsState) => state.all,
    current: (state: CustomFormsState) => state.current,
  },
  mutations: {
    SET_CURRENT(state: CustomFormsState, customForm: CustomForm) {
      state.current = customForm;
    },
    SET_ALL(state: CustomFormsState, customForms: CustomForm[]) {
      state.all = customForms;
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const customForm = await CustomFormsService.fetchOne(id);
      commit("SET_CURRENT", customForm);
    },
    async fetchAll({ commit }: any) {
      const customForms = await CustomFormsService.fetchAll();
      commit("SET_ALL", customForms);
    },
    async update({ commit }: any, data: CustomForm) {
      //const customForm = await CustomFormsService.update(data.id, data);
      return CustomForm;
    },
    async create({ commit }: any, data: CustomForm) {
      const customForm = await CustomFormsService.create(data);
      return customForm;
    },
    async delete({ commit }: any, id: string) {
      const customForm = await CustomFormsService.delete(id);
      return customForm;
    },
  },
} as any;

export default CustomForms;
