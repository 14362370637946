import axios from "@/plugins/axios.plugin";

const IFlyChat = {
  namespaced: true,
  state: {
    code: null,
  },
  getters: {
    code: (state: { code: any }) => state.code,
  },
  mutations: {
    SET_CODE(state: { code: any }, code: any) {
      state.code = code
    },
  },
  actions: {
    async create({ commit, rootGetters }: any) {
      const code = await axios.post<any>(`iflychat/create`, rootGetters['Auth/currentUser'].id);
      commit("SET_CODE", code)
    }
  }
} as any

export default IFlyChat