import SettingsService from "@/services/SettingsService";

interface RegistrationState {
  form: any;
}

const Registration = {
  namespaced: true,
  state: {
    form: [],
  },
  getters: {
    form: (state: RegistrationState) => state.form,
  },
  mutations: {
    SET_FORM(state: RegistrationState, form: any) {
      state.form = form || [];
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const form = await SettingsService.fetchOne(id);
      commit("SET_FORM", form.setting_value);
    },
    async fetchAll({ commit }: any) {
      const form = await SettingsService.fetchOne("registration");
      commit("SET_FORM", form.setting_value);
    },
    async update({ commit }: any, payload: { id: string; data: any }) {
      const response = await SettingsService.update(payload.id, payload.data);
    },
  },
} as any;

export default Registration;
