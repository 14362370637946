<template>
  <component :is="layout">
    <router-view class="router-class" />
  </component>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      if (this.$route.meta && this.$route.meta.sidebar) {
        return "sidebar-layout";
      }
      return "no-sidebar-layout";
    },
  },
};
</script>
