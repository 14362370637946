import SettingsService from "@/services/SettingsService";
import WherebyService from "@/services/WherebyService";

const Whereby = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: {all: any }) => state.all,
  },
  mutations: {
    SET_ALL(state: { all: any }, all: any) {
      state.all = all
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const whereby = await SettingsService.fetchOnePublic("whereby");
      commit("SET_ALL", whereby.setting_value);
    },
    async update({ commit }: any, data: any) {
      await SettingsService.update("whereby", data);
    },
    async createRoom({ commit }: any, data: any) {
      const response = await WherebyService.createRoom(data)
      return response
    }
  }
} as any

export default Whereby