import StatisticsService from "@/services/StatisticsService";

const Statistics = {
  namespaced: true,
  state: {
    login: {},
    uniqueLogin: {},
    views: {},
  },
  getters: {
    login: (state: { login: any }) => state.login,
  },
  mutations: {
    SET_LOGIN(state: { login: any }, login: any) {
      state.login = login
    },
    SET_UNIQUE_LOGIN(state: { uniqueLogin: any }, uniqueLogin: any) {
      state.uniqueLogin = uniqueLogin
    },
    SET_VIEWS(state: { views: any }, views: any) {
      state.views = views
    },
  },
  actions: {
    async fetchLogin({ commit }: any) {
      const login = await StatisticsService.fetchLogin();
      commit("SET_LOGIN", login);
    },
    async fetchUniquelogin({ commit }: any) {
      const uniqueLogin = await StatisticsService.fetchUniqueLogin();
      commit("SET_UNIQUE_LOGIN", uniqueLogin);
    },
    async fetchViews({ commit }: any) {
      const views = await StatisticsService.fetchViews();
      commit("SET_VIEWS", views);
    },
  }
} as any

export default Statistics