import SettingsService from "@/services/SettingsService";

const Dash = {
  namespaced: true,
  state: {
    content: []
  },
  getters: {
    intro: (state: { content: any }) => state.content.intro,
    allBlocks: (state: { content: any }) => state.content.blocks,
    interaction: (state: { content: any }) => state.content.interaction,
  },
  mutations: {
    SET_CONTENT(state: { content: any }, content: any) {
      state.content = content;
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const response = await SettingsService.fetchOne("dashboard");
      commit("SET_CONTENT", response.setting_value);
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("dashboard", data);
    },
  },
} as any;

export default Dash;
