import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class StatisticsService extends BaseService<any> {
  get endpoint() {
    return "/statistics";
  }

  async fetchLogin(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/login`);
    return response.data;
  }

  async fetchUniqueLogin(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/uniqueLogin`);
    return response.data;
  }
  
  async fetchViews(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/views`);
    return response.data;
  }
}
export default new StatisticsService();