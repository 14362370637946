import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";

const dictionary = {
  en: {
    messages: {
      required_if: (fieldName: string, params: any[], data?: any) => {
        return `The ${fieldName} field is required when the ${params[0]} field is '${params[1]}'`;
      },
    },
  },
};

// Override and merge the dictionaries
Validator.localize(dictionary);

Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
