import SettingsService from "@/services/SettingsService";
import moment from "moment"

interface ScheduleState {
  all: { [key: string]: any };
}

const Schedule = {
  namespaced: true,
  state: {
    all: []
  },
  getters: {
    all: (state: ScheduleState) => state.all,
    byTitle: (state: ScheduleState) => (title: any) => {
      return state.all.find((schedule: any) => schedule.title === title);
    },
  },
  mutations: {
    SET_ALL(state: ScheduleState, schedules: any) {
      state.all = schedules;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const schedules = await SettingsService.fetchOne("schedule");
      commit("SET_ALL", schedules.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const posts = await SettingsService.update("schedule", payload);
    },
  },
} as any;

export default Schedule;
