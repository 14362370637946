import AttachmentsService from "@/services/AttachmentsService";

const Attachments = {
  namespaced: true,
  state: {
    attachments: {},
    attachment: []
  },
  getters: {
    allAttachments: (state: { attachments: any }) => state.attachments,
    attachment: (state: {attachment: any }) => state.attachment
  },
  mutations: {
    SET_CURRENT(state: { attachment: any }, attachment: any) {
      state.attachment = attachment
    },
    SET_ALL(state: { attachments: any }, attachments: any) {
      state.attachments = attachments
    },
    EMPTY_CURRENT(state: { attachment: any }) {
      state.attachment = {
        id: "",
        active: false,
        title: {
          en: "",
          nl: ""
        },
        description: {
          en: "",
          nl: ""
        },
        category: {
          en: "",
          nl: ""
        },
        type: "",
        thumbnail: "",
        url: "",
        lang: ""
      }
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const attachment = await AttachmentsService.fetchOne(id);
      commit("SET_CURRENT", attachment);
    },
    async fetchAll({ commit }: any, filter: any) {
      const attachments = await AttachmentsService.fetchAll();
      commit("SET_ALL", attachments);
    },
    async delete({ commit }: any, id: string) {
      await AttachmentsService.delete(id);
    },
    async update({ commit }: any, attachment: any) {
      const newAttachment = await AttachmentsService.update(attachment.id, attachment);
    },
  }
} as any

export default Attachments