import SettingsService from "@/services/SettingsService";

interface DefaultMailsState {
  all: any;
}

const defaultMails = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: DefaultMailsState) => state.all,
  },
  mutations: {
    SET_ALL(state: DefaultMailsState, defaultMails: any) {
      state.all = defaultMails;
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const defaultMails = await SettingsService.fetchOne("default-mails");

      commit("SET_ALL", defaultMails?.setting_value || {});
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("default-mails", data);
    },
  },
} as any;

export default defaultMails;
