import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class SocialPostsService extends BaseService<any> {
  get endpoint() {
    return "/socialposts";
  }
}

export default new SocialPostsService();
