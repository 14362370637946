import { StaticPage } from "@/models/static-page.model";
import StaticPagesService from "@/services/StaticPagesService";
import Vue from "vue";

interface PagesState {
  all: StaticPage[];
  current: StaticPage;
}

const Pages = {
  namespaced: true,
  state: {
    all: [],
    current: new StaticPage(),
  },
  getters: {
    all: (state: PagesState) => state.all,
    current: (state: PagesState) => state.current,
  },
  mutations: {
    SET_ALL(state: PagesState, pages: any) {
      state.all = pages;
    },
    SET_CURRENT(state: PagesState, page: StaticPage) {
      Vue.set(state, "current", page);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const pages = await StaticPagesService.fetchAllUnpaginated();
      commit("SET_ALL", pages);
    },
    async create({ commit }: any, data: StaticPage) {
      const response = await StaticPagesService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async update({ commit }: any, data: StaticPage) {
      const response = await StaticPagesService.update(data.id as string, data);
      commit("SET_CURRENT", response);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await StaticPagesService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await StaticPagesService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
    async fetchByLink({ commit }: any, link: string) {
      const response = await StaticPagesService.fetchByLink(link);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default Pages;
