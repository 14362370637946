import BaseService from "./base-service";
import axios from "@/plugins/axios.plugin";

class StylingService extends BaseService<any> {
  get endpoint() {
    return "/styling";
  }

  async generate() {
    await axios.post(`${this.endpoint}/generate`)
  }
}

export default new StylingService();