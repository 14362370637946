import axios from "@/plugins/axios.plugin";

class AuthService {
  async adminLogin(email: string, password: string) {
    const response = await axios.post("/register", {
      email,
      password,
    });

    return response.data;
  }

  async login(email: string, code: string) {
    const response = await axios.post("/login", {
      email,
      code,
    });

    return response.data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  async me() {
    const response = await axios.get("/me");
    return response.data;
  }

  async addRegistration(data) {
    const response = await axios.post("/registration", data);

    return response.data;
  }

  async addFormRegistration(data) {
    const response = await axios.post("/registration/form" + data.form, data);
    return response.data;
  }

  async register(
    email: string,
    first_name: string,
    last_name: string,
    role: string,
    code?: string
  ) {
    return axios
      .post("/register", {
        email,
        first_name,
        last_name,
        role,
        code,
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AuthService();
