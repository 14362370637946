import { User } from "@/models/user.model";
import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class UsersService extends BaseService<any> {
  get endpoint() {
    return "/users";
  }

  async fetchOnline(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/online`, {
      responseType: "arraybuffer",
    });
    return response.data;
  }

  async export(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/export`, {
      responseType: "arraybuffer",
    });
    return response.data;
  }

  async import(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${this.endpoint}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    });
    return response.data;
  }

  async setHasPaid(userId: string): Promise<User> {
    const response = await axios.put<User>(`${this.endpoint}/${userId}/paid`);
    return response.data;
  }

  async setHasNotPaid(userId: string): Promise<User> {
    const response = await axios.put<User>(
      `${this.endpoint}/${userId}/not-paid`
    );
    return response.data;
  }
  async fetchGroups(): Promise<string[]> {
    const response = await axios.get<string[]>(`${this.endpoint}-groups`);
    return response.data;
  }
}

export default new UsersService();
