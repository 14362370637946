import axios from "@/plugins/axios.plugin";
import SettingsService from "@/services/SettingsService";

const Translations = {
  namespaced: true,
  state: {
    translations: {},
  },
  getters: {
    translations: (state: { translations: any }) => state.translations,
    getTranslationByLanguage: (state: any) => (lang: any) => {
      return state.translations[lang];
    },
  },
  mutations: {
    SET_TRANSLATIONS(state: { translations: any }, translations: any) {
      state.translations = translations;
    },
  },
  actions: {
    async getTranslations({ commit }: any) {
      const response = await axios.get("/settings/public/translations", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "json",
      });
      commit("SET_TRANSLATIONS", response.data.setting_value);
    },
    async update({ commit }: any, data: any) {
      await SettingsService.update("translations", data);
    },
  },
} as any;

export default Translations;
