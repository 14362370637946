import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class WherebyService extends BaseService<any> {
  get endpoint() {
    return "/whereby";
  }

  async createRoom(body: any) {
    const response = await axios.post<any>(`${this.endpoint}/create`, body);
    return response.data;
  }
}

export default new WherebyService();
